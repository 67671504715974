<template>
    <div class="container inputOutput">
        <top-navigation-bar
            :title="getFormulaTitle"
            right-nav="reset"
            @click="doReset"
        />
        <div>
            <div class="action-btn border-0 divider">
                <img
                    class="formulaImage-list"
                    :alt="`${getFormulaTitle} formula`"
                    :src="require(`@/assets/CIformulaImgs/${getFormulaName}.jpg`)"
                >
                <div>
                    <button
                        v-if="getFormulaName === 'TwoISmallNotEqual'"
                        class="action-btn w-50"
                        @click="showDfFormulaModel()"
                    >
                        See df Formula
                    </button>
                </div>
            </div>
            <button
                v-if="oneImport"
                class="action-btn m-3"
                @click="showOneImportModal"
            >
                Import Dataset
            </button>
            <button
                v-else-if="importChoice"
                class="action-btn m-3"
                @click="showImportChoiceModal"
            >
                Import Datasets
            </button>
            <button
                v-else-if="twoImports"
                class="action-btn m-3"
                @click="showTwoImportsModal"
            >
                Import Datasets
            </button>
            <input-single
                v-model="cl"
                label="Confidence Level"
                style-class="input-single"
                place-holder="%"
            />
            <div v-if="getFormulaTitle === 'One Mean'">
                <input-single
                    v-model="n"
                    :label="{text: 'Sample Size', mathChar: 'n'}"
                    style-class="input-single"
                />
                <input-single
                    v-model="x̄"
                    label="Mean (𝒙̄)"
                    style-class="input-single"
                />
                <input-single
                    v-model="s"
                    :label="{text: 'Std Dev', mathChar: 's'}"
                    style-class="input-single"
                />
            </div>

            <div v-else-if="getFormulaTitle === 'One Proportion'">
                <input-single
                    v-model="x"
                    :label="{text: 'Successes', mathChar: '𝒙'}"
                    style-class="input-single"
                />
                <input-single
                    v-model="n"
                    :label="{text: 'Sample Size', mathChar: 'n'}"
                    style-class="input-single"
                />
            </div>

            <div v-else-if="getFormulaTitle === 'Difference in Means'">
                <div class="inline-input-view multi d-flex">
                    <input-single
                        v-model="n1"
                        label="𝒏₁"
                        style-class="input-single group"
                        group
                    />
                    <input-single
                        v-model="n2"
                        label="𝒏₂"
                        style-class="input-single group"
                        group
                    />
                </div>
                <div class="inline-input-view multi d-flex">
                    <input-single
                        v-model="x̄1"
                        label="𝒙̄₁"
                        style-class="input-single group"
                        group
                    />
                    <input-single
                        v-model="x̄2"
                        label="𝒙̄₂"
                        style-class="input-single group"
                        group
                    />
                </div>
                <div class="inline-input-view multi d-flex">
                    <input-single
                        v-model="s1"
                        label="𝐬₁"
                        style-class="input-single group"
                        group
                    />
                    <input-single
                        v-model="s2"
                        label="𝐬₂"
                        style-class="input-single group"
                        group
                    />
                </div>
            </div>

            <div v-else-if="getFormulaTitle === 'Difference in Proportions'">
                <div class="inline-input-view multi d-flex">
                    <input-single
                        v-model="x1"
                        label="𝒙₁"
                        style-class="input-single group"
                        group
                    />
                    <input-single
                        v-model="x2"
                        label="𝒙₂"
                        style-class="input-single group"
                        group
                    />
                </div>
                <div class="inline-input-view multi d-flex">
                    <input-single
                        v-model="n1"
                        label="𝒏₁"
                        style-class="input-single group"
                        group
                    />
                    <input-single
                        v-model="n2"
                        label="𝒏₂"
                        style-class="input-single group"
                        group
                    />
                </div>
            </div>

            <div v-else-if="getFormulaTitle === 'Paired Difference in Means'">
                <input-single
                    v-model="n"
                    :label="{text: 'Sample Size', mathChar: 'n'}"
                    style-class="input-single"
                />
                <input-single
                    v-model="d"
                    :label="{text: 'Mean', mathChar: 'd̄', helpIcon: true}"
                    style-class="input-single"
                    @icon-click="showHelpModal()"
                />
                <input-single
                    v-model="sd"
                    :label="{text: 'Std Dev', mathChar: 'S', subScript: 'd'}"
                    style-class="input-single"
                />
            </div>

            <div v-else-if="getFormulaTitle === 'One Variance'">
                <input-single
                    v-model="n"
                    :label="{text: 'Sample Size', mathChar: 'n'}"
                    style-class="input-single"
                />
                <input-single
                    v-model="s"
                    :label="{text: 'Std Dev', mathChar: 'S', subScript: 'd'}"
                    style-class="input-single"
                />
            </div>

            <div v-else-if="getFormulaTitle === 'Two Variances'">
                <div class="inline-input-view multi d-flex">
                    <input-single
                        v-model="s1"
                        label="𝐬₁"
                        style-class="input-single group"
                        group
                    />
                    <input-single
                        v-model="s2"
                        label="𝐬₂"
                        style-class="input-single group"
                        group
                    />
                </div>
                <div class="inline-input-view multi d-flex">
                    <input-single
                        v-model="n1"
                        label="𝒏₁"
                        style-class="input-single group"
                        group
                    />
                    <input-single
                        v-model="n2"
                        label="𝒏₂"
                        style-class="input-single group"
                        group
                    />
                </div>
            </div>

            <div class="sticky-bottom m-3">
                <button
                    class="action-btn w-75"
                    block
                    @click="onSubmit()"
                >
                    Calculate
                </button>
            </div>

            <div
                v-if="ci"
                class="output-container"
            >
                <div
                    v-if="z && !p̂ && !p̂1"
                    class="output-view"
                >
                    <h4><span>Z</span> Value: {{ z }}</h4>
                </div>
                <div
                    v-if="t && df && !sp2"
                    class="output-view"
                >
                    <h4><span>t</span> Value: {{ t }}</h4>
                    <h4><span>df</span> Value: {{ df }}</h4>
                </div>
                <div
                    v-if="z && p̂"
                    class="output-view"
                >
                    <h4><span>Z</span> Value: {{ z }}</h4>
                    <h4>Proportion (𝒑̂): {{ p̂ }}</h4>
                </div>
                <div
                    v-if="t && df && sp2"
                    class="output-view"
                >
                    <h4><span>t</span> Value: {{ t }}</h4>
                    <h4><span>df</span> Value: {{ df }}</h4>
                    <h4><span>S<sub>𝑝</sub>²</span> Value: {{ sp2 }}</h4>
                </div>
                <div
                    v-if="z && p̂1 && p̂2"
                    class="output-view"
                >
                    <h4>𝒑̂₁ Value: {{ p̂1 }}</h4>
                    <h4>𝒑̂₂ Value: {{ p̂2 }}</h4>
                    <h4>𝒛 Value: {{ z }}</h4>
                </div>

                <div
                    v-if="chiLower && chiUpper"
                >
                    <div
                        class="image-row justify-content-around mt-2"
                    >
                        <div>
                            <img
                                class="formulaImage-char-med"
                                :src="require(`@/assets/CIformulaImgs/variance/${outputDisplay.chiLowerImg}`)"
                            >
                            <h4>= {{ chiLower }}</h4>
                        </div>
                        <div>
                            <img
                                class="formulaImage-char-med"
                                :src="require(`@/assets/CIformulaImgs/variance/${outputDisplay.chiUpperImg}`)"
                            >
                            <h4>= {{ chiUpper }}</h4>
                        </div>
                    </div>

                    <div class="formulaImage-output-view mt-5">
                        <h4>{{ clDisplay + '% ' }} Confidence Level for {{ outputDisplay.text[0] }}</h4>
                    </div>
                    <div class="image-row justify-content-center">
                        <h4>{{ outputDisplay.text[1] }}</h4>
                        <img
                            class="formulaImage-char-sm"
                            :src="require(`@/assets/CIformulaImgs/variance/${outputDisplay.sigSqImg}`)"
                        >
                        <h4>: </h4>
                        <h4 class="ci-output-text">
                            ({{ ci.lowerEnd }}, {{ ci.upperEnd }})
                        </h4>
                    </div>
                </div>
                <div
                    v-else
                    class="my-4"
                >
                    <h4>
                        {{ clDisplay + '% ' }}
                        {{ getFormulaName === 'TwoV' ? 'Confidence Level for ratio of' : 'Confidence Interval for' }}
                        {{ ciTypeDisplay }}:
                    </h4>
                    <h4 class="ci-output-text">
                        ({{ ci.lowerEnd }}, {{ ci.upperEnd }})
                    </h4>
                </div>
            </div>
            <modal
                ref="modalFormula"
                title="Formula"
                style-class="modal-bottom"
                modal-type="general"
            >
                <template #body>
                    <img
                        class="formulaImage"
                        alt="binomial formula"
                        src="@/assets/helpImgs/popUpWelchs.png"
                    >
                </template>
            </modal>
            <modal
                ref="modalHelp"
                title="Help"
                style-class="modal-bottom"
                modal-type="general"
            >
                <template #body>
                    <img
                        class="formulaImage"
                        alt="example paired differences"
                        src="@/assets/helpImgs/popUpDependent.png"
                    >
                </template>
            </modal>
            <PairedDifferenceModal
                v-if="importChoice"
                ref="modalImportChoice"
                :min-sample-size="minSampleSize"
                :select-one-filter="selectOneFilter"
                :select-one-error-message="selectOneErrorMessage"
                :select-two-filter-first="selectTwoFilterFirst"
                :select-two-verify="selectTwoVerify"
                :select-two-error-message="selectTwoErrorMessage"
                :select-two-filter-second="selectTwoFilterSecond"
                @select="importPairedDifference"
            />
            <SelectDatasetModal
                v-else-if="oneImport"
                ref="modalOneImport"
                :min-sample-size="minSampleSize"
                :filter="selectOneFilter"
                :error-message="selectOneErrorMessage"
                @select="importOneDataset"
            />
            <SelectTwoDatasetsModal
                v-else-if="twoImports"
                ref="modalTwoImports"
                :min-sample-size="minSampleSize"
                :filter-first="selectTwoFilterFirst"
                :verify="selectTwoVerify"
                :error-message="selectTwoErrorMessage"
                :filter-second="selectTwoFilterSecond"
                @select="importTwoDatasets"
            />
        </div>
    </div>
</template>

<script>
import InputSingle from '@/components/input-single.vue'
import Modal from '@/components/modal.vue'
import PairedDifferenceModal from '@/components/paired-difference-modal.vue'
import SelectDatasetModal from '@/components/select-dataset-modal.vue'
import SelectTwoDatasetsModal from '@/components/select-two-datasets-modal.vue'
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import confidenceData from '@/js/confidence/confidence-data.js'
import sData from '@/js/summarizeData/summarize-data.js'
import localStore from '@/store/localStore.js'
import _ from 'lodash'

export default {
    name: 'InputCI',
    components: {
        TopNavigationBar,
        InputSingle,
        Modal,
        PairedDifferenceModal,
        SelectDatasetModal,
        SelectTwoDatasetsModal,
    },
    data () {
        return {
            allData: {},
            // Input
            cl: '', // Confidence Level
            sd: '', // Std. Dev
            s: '', // Sample Standard Deviation
            s1: '', // Sample Standard Deviation One
            s2: '', // Sample Standard Deviation Two

            d: '', // Mean
            x̄: '', // Sample Mean
            x̄1: '', // Sample Mean One
            x̄2: '', // Sample Mean Two

            x: '', // Successes
            x1: '', // Sample Mean one
            x2: '', // Sample Mean two

            n: '', // Sample size
            n1: '', // Sample size one
            n2: '', // Sample size two

            // Output
            clDisplay: '', // Confidence Level
            z: '',
            t: '',
            df: '', // Degrees Freedom
            sp2: '',
            p̂: '', // Sample Proportion
            p̂1: '', // Smaple Proportion one
            p̂2: '', // Smaple Proportion two
            chiLower: '', // Variance Lower
            chiUpper: '', // Variance Upper
            ci: '', // Confidence Interval

            outputDisplay: {
                chiLowerImg: 'ChiSquare_Lower.png',
                chiUpperImg: 'ChiSquare_Upper.png',
                sigSqImg: 'Sigma_Squared.png',
                text: []
            },
        }
    },
    computed: {
        getFormulaTitle () {
            return _.get(this.allData.formula, 'title', '')
        },
        getFormulaName () {
            return _.get(this.allData.formula, 'name', '')
        },
        getChoiceName () {
            return localStore.getStore('confidence-data').choice.name
        },
        ciTypeDisplay () {
            var formula = this.getFormulaTitle
            var output
            if (formula === 'One Mean') {
                output = '𝝁'
            } else if (formula === 'One Proportion') {
                output = '𝒑'
            } else if (formula === 'Difference in Proportions') {
                output = '𝒑₁ - 𝒑₂'
            } else {
                output = '𝝁₁ - 𝝁₂'
            }
            return output
        },
        noImportFormulas () {
            return ['OneP', 'TwoP']
        },
        importChoiceFormulas () {
            return ['TwoDLarge', 'TwoDSmall']
        },
        importChoice () {
            return this.importChoiceFormulas.includes(this.getFormulaName)
        },
        canImport () {
            return !this.noImportFormulas.includes(this.getFormulaName)
        },
        oneImport () {
            return this.getChoiceName === 'one' && this.canImport
        },
        twoImports () {
            return this.getChoiceName === 'two' && this.canImport
        },
        minSampleSize () {
            switch (this.getFormulaName) {
            case 'OneZ':
            case 'TwoDLarge':
            case 'TwoILarge':
                return 30
            }
            return undefined
        },
        selectOneErrorMessage () {
            switch (this.getFormulaName) {
            case 'OneZ':
            case 'TwoDLarge':
                return 'Large samples require that n ≥ 30'
            case 'OneT':
            case 'TwoDSmall':
                return 'Small samples require that 2 ≤ n < 30'
            }
            return undefined
        },
        selectTwoErrorMessage () {
            switch (this.getFormulaName) {
            case 'TwoISmallEqual':
            case 'TwoISmallNotEqual':
                return 'Both sample sizes must be n ≥ 2 and at least one ' +
                    'must be n < 30'
            case 'TwoDLarge':
                return 'Both sample sizes must be n ≥ 2 and must be equal'
            case 'TwoDSmall':
                return 'Both sample sizes must be 2 ≤ n < 30 and must be ' +
                    'equal'
            }
            return undefined
        },
    },
    beforeMount () {
        var store = localStore.getStore('confidence-data')
        if (store) {
            _.assign(this, { allData: store })
            var formulaStore = _.get(store, `${this.getFormulaName}`, '')
            if (formulaStore) {
                _.assign(this, formulaStore.input)
            }
        }

        // set formula images
        if (this.getFormulaTitle === 'Two Variances') {
            _.assign(this.outputDisplay, {
                chiLowerImg: 'F_Lower.png',
                chiUpperImg: 'F_Upper.png',
                sigSqImg: 'Sigma_Squared_over.png',
                text: [
                    'the ratio of', 'variances'
                ]
            })
        }
    },
    methods: {
        doReset () {
            var inputs = _.omit(this.$data, ['allData', 'outputDisplay'])
            var inputsReset = _.mapValues(inputs, v => { return '' })
            _.assign(this, inputsReset)
        },
        showHelpModal () {
            this.$refs.modalHelp.show()
        },
        showDfFormulaModel () {
            this.$refs.modalFormula.show()
        },
        showOneImportModal () {
            this.$refs.modalOneImport.show()
        },
        showTwoImportsModal () {
            this.$refs.modalTwoImports.show()
        },
        showImportChoiceModal () {
            this.$refs.modalImportChoice.show()
        },
        nSmall (dataset) {
            return sData.getDataValues(dataset.items).length < 30
        },
        selectOneFilter (dataset) {
            switch (this.getFormulaName) {
            case 'OneT':
            case 'TwoDSmall':
                return this.nSmall(dataset)
            }
            return true
        },
        selectTwoFilterFirst (dataset) {
            switch (this.getFormulaName) {
            case 'TwoDSmall':
                return this.nSmall(dataset)
            }
            return true
        },
        selectTwoVerify (datasets) {
            switch (this.getFormulaName) {
            case 'TwoISmallEqual':
            case 'TwoISmallNotEqual':
                return _.filter(datasets, (dataset) => {
                    return this.nSmall(dataset)
                }).length > 0
            }
            return true
        },
        selectTwoFilterSecond (dataset, first) {
            switch (this.getFormulaName) {
            case 'TwoISmallEqual':
            case 'TwoISmallNotEqual':
                return this.nSmall(first) || this.nSmall(dataset)
            }
            return true
        },
        getData (dataset) {
            const summaryData = sData.calculateDataCheck(dataset.items)
            if (!_.isEmpty(summaryData.errors)) {
                alert(_.head(summaryData.errors))
                return null
            }
            return summaryData.output
        },
        importPairedDifference (dataset) {
            const data = this.getData(dataset)
            if (data) {
                this.sd = data.stdDev
                this.d = data.mean
                this.n = data.sampleSize
            }
        },
        importOneDataset (dataset) {
            const data = this.getData(dataset)
            if (data) {
                this.n = data.sampleSize
                this.s = data.stdDev
                this.x̄ = data.mean
            }
        },
        importTwoDatasets (first, second) {
            const dataFirst = this.getData(first)
            const dataSecond = this.getData(second)
            if (dataFirst && dataSecond) {
                this.s1 = dataFirst.stdDev
                this.s2 = dataSecond.stdDev
                this.x̄1 = dataFirst.mean
                this.x̄2 = dataSecond.mean
                this.n1 = dataFirst.sampleSize
                this.n2 = dataSecond.sampleSize
            }
        },
        onSubmit () {
            var { allData } = this
            const output = confidenceData.calculateCI(this)
            if (!output) {
                console.log('Calculation Error')
            } else {
                _.assign(this, output.data)
                _.set(allData, `${this.getFormulaName}`, { input: output.input, output: output.data })
                localStore.setStoreItem('confidence-data', allData)
            }
        }
    },
}

</script>

<style lang="css">
    .ci-output-text {
        padding: 0.5rem;
        letter-spacing: 0.5px;
    }
</style>
